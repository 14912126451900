import React, {useState} from 'react';
import { Range } from 'react-range';
import BlockTitle from "../components/block-title";
import { Container } from "theme-ui";
import {numberWithCommas} from "../utils/formatters";
import {calculateRebate} from "../utils/rebate";

function RebateCalculator() {
  const [values, setValues] = useState([1200000]);

  return (
    <div style={styles.container} id="rebate-calculator">
      <Container>
        <BlockTitle
          title="Rebate calculator"
        />
        <div style={styles.rangeContainer}>
          <Range
            step={50000}
            min={400000}
            max={2000000}
            values={values}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '8px',
                  width: '100%',
                  backgroundColor: '#ccc'
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props, value }) => (
              <div {...props}
              style={{...props.style,
                margin: 0,
                padding: '10px 30px',
                backgroundColor: '#ffc800',
                borderRadius: '30px',
                color: '#ffffff',
              }}
              >
                <div style={{fontSize: 15, textAlign: "center"}}>House price</div>
                <div style={{fontSize: 20, fontWeight: 600, textAlign: "center"}}>${numberWithCommas(value)}</div>
              </div>
            )}
          />
        </div>
        <div style={styles.textContainer}>
          <div style={styles.titleText}>Rebate</div>
          <div style={styles.rebateText}>${numberWithCommas(calculateRebate(values[0]))}*</div>
        </div>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    marginTop: '200px',
  },
  rangeContainer: {
    width: '80%',
    margin: '50px auto',
  },
  textContainer: {
    textAlign: "center"
  },
  rebateText: {
    fontSize: 25,
    fontWeight: 800
  },
  priceText: {
    fontSize: 20
  },
  titleText: {
    fontSize: 15
  }
}

export default RebateCalculator;