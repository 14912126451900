import React, {useState} from 'react';
import {
  Box,
  Container,
  Button,
  Input,
} from 'theme-ui';
import {InvalidInputError, joinWaitlist} from "../api/join-waitlist";
import { useToasts } from 'react-toast-notifications';
import {numberWithCommas} from "../utils/formatters";
import {calculateRebate} from "../utils/rebate";
import firebase from "gatsby-plugin-firebase"
import PuffLoader from "react-spinners/ClipLoader";


const Banner = () => {
  const [email, setEmail] = useState(null);
  const [joining, setJoining] = useState(false);
  const { addToast } = useToasts();

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    setEmail(value);
  }

  const onClick = () => {
    firebase.analytics().logEvent('join_waitlist_click');
    setJoining(true);

    joinWaitlist(email)
      .then(result => {
        addToast(`"${email}" has successfully joined the waitlist`, { appearance: 'success' });
        firebase.analytics().logEvent('join_waitlist_success');
      })
      .catch(error => {
        console.error(error);
        if (error instanceof InvalidInputError) {
          firebase.analytics().logEvent('join_waitlist_invalid_email');
          addToast(error.message, { appearance: 'error' });
        } else {
          firebase.analytics().logEvent('join_waitlist_failures');
          addToast("Failed to join the waitlist. Please retry.", { appearance: 'error' });
        }
      })
      .finally(() => setJoining(false));
  }

  let buttonContent;
  if (joining) {
    buttonContent = (<PuffLoader color={'#ffffff'} loading={joining} size={25} />);
  } else {
    buttonContent = 'Join waitlist';
  }

  return (
    <Box sx={styles.banner} id="banner">
      <Container sx={styles.container}>
        <div style={styles.titleContainer}>
          <div style={styles.content}>
            <h3 style={styles.content.h3}>
              Find 100% rebate buyer agents
            </h3>
            <p style={styles.content.p}>
              Join the waitlist to be matched with a flat-fee buyer agent. Get up to ${numberWithCommas(calculateRebate(1000000))}* rebate on a 1 million dollars house.
            </p>
          </div>
        </div>
        <div style={styles.joinContainer}>
          <Input
            name="subscribe"
            id="subscribe"
            placeholder="Insert your email"
            style={styles.joinContainer.input}
            onChange={handleInputChange}
          />
          <Button type="button" sx={styles.joinContainer.button} onClick={onClick}>
            {buttonContent}
          </Button>
        </div>
      </Container>
    </Box>
  );
};

export default Banner;

const styles = {
  banner: {
    pt: ['90px', null, null, null, '110px', '150px'],
    pb: ['50px', null, null, null, '60px', null, '0'],
    backgroundColor: '#F6F8FB',
    overflow: 'hidden',
  },
  container: {
    width: [null, null, null, null, null, null, '1390px'],
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    h3: {
      color: 'black',
      fontWeight: 'bold',
      lineHeight: [1.39],
      letterSpacing: ['-.7px', '-1.5px'],
      width: ['100%'],
      maxWidth: ['100%', null, null, '90%', '100%', '540px'],
      fontSize: '55px',
      marginBottom: '20px',
    },
    p: {
      fontSize: [1, null, null, 2, null, 3],
      lineHeight: ['26px', null, null, null, 2.33],
      color: 'text_secondary',
      width: ['100%'],
      maxWidth: ['100%', null, null, null, null, '410px'],
      br: {
        display: ['none', null, null, null, 'inherit'],
      },
    },
  },
  joinContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '50px',
    input: {
      borderRadius: ['4px'],
      maxWidth: '300px',
      backgroundColor: '#fff',
      fontSize: [1, null, null, 2],
      border: 'none',
      outline: 'none',
      boxShadow: '0px 10px 50px rgba(48, 98, 145, 0.08)',
      minWidth: ['110px', null, null, null, 2, '170px'],
      marginBottom: '20px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: [1, null, null, null, 2, '20px'],
      borderRadius: ['4px'],
      padding: ['0 15px'],
      ml: ['10px'],
      minWidth: ['110px', null, null, null, 2, '170px'],
      marginBottom: '20px',
    },
  },
  partner: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    mb: ['40px'],
    '> div + div': {
      ml: ['10px', null, null, '20px', null, '30px'],
    },
    image: {
    },
    span: {
      fontSize: [1, null, null, null, 2],
      color: '#566272',
      lineHeight: [1],
      opacity: 0.6,
      display: 'block',
      mb: ['20px', null, null, null, '0px'],
      mr: [null, null, null, null, '20px'],
      flex: ['0 0 100%', null, null, null, '0 0 auto'],
    },
  },
};
