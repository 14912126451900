import React from 'react';
import {Container} from "theme-ui";
import {numberWithCommas} from "../utils/formatters";
import Constants from "../utils/constants";


function SmallPrint() {
  return (
    <Container style={styles.container}>
      * based on a 2.5% selling agent commission. Rebate will be reduced by a ${numberWithCommas(Constants.FLAT_FEE)} flat fee. Rebate is subject to lender approval.
    </Container>
  );
}

const styles = {
  container: {
    fontSize: 12,
    textAlign: 'right',
    marginTop: 40,
    pt: ['110px', null, null, null, '150px', '200px'],
    pb: ['50px', null, null, null, '60px', null, '0'],
    overflow: 'hidden',
    width: [null, null, null, null, null, null, '1390px'],
  },
}

export default SmallPrint;
