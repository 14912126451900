import axios from "axios";

export function InvalidInputError(message) {
  this.name = 'MyError';
  this.message = message;
  this.stack = (new Error()).stack;
}
InvalidInputError.prototype = new Error;

export function joinWaitlist(email) {
  return axios.post('/api/joinWaitlist', null, {params: {email}})
    .catch((error) => {
      if (error.response.status === 400) {
        throw new InvalidInputError(error.response.data.error);
      }
      throw error;
    });
}