import React from 'react';
import 'swiper/swiper-bundle.min.css';
import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';

import SEO from '../components/seo';
import Layout from '../components/layout';

import Banner from '../sections/banner';
import Services from '../sections/services';
import { ToastProvider } from 'react-toast-notifications';
import SmallPrint from "../sections/small-print";
import RebateCalculator from "../sections/rebate-calculator";
import Faq from "../sections/faq";


const headerData = {
  menuItems: [
    {
      path: 'banner',
      label: 'Home',
    },
    {
      path: 'services',
      label: 'Advantages',
    },
    {
      path: 'rebate-calculator',
      label: 'Rebate calculator',
    },
    {
      path: 'faq',
      label: 'FAQ',
    },
  ]
}

export default function IndexPage() {
  return (
    <ToastProvider autoDismiss={true}>
      <Layout headerData={headerData}>
        <SEO title="xDomu" />
        <Banner />
        <Services />
        <RebateCalculator />
        <SmallPrint />
        <Faq />
      </Layout>
    </ToastProvider>
  );
}
