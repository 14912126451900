import React from 'react';
import {Box, Container, Grid, Heading, Image, Text} from "theme-ui";
import BlockTitle from "../components/block-title";
import {Link} from "../components/link";


const faqs = [
  {
    question: 'Where do the agents operate?',
    answer: (<div>Currently our agents operate in the <b>San Francisco Bay Area</b>. If you are interested in other areas please email contact@xdomu.com.</div>),
  },
  {
    question: 'Do the agents do house tours?',
    answer: 'No, but most of the listings now have now open house days. Please check on websites like Zillow/Redfin/Realtor for date and times.'
  },
  {
    question: 'Will the agents find the house for me?',
    answer: 'No, the agents won\'t search houses for you. Please use websites like Redfin/Realtor/Zillow to look for houses.'
  },
  {
    question: 'How do you make money?',
    answer: 'We are currently offering this service for free to real estate agents and buyers.'
  },
  {
    question: 'I am an agent, how can I subscribe?',
    answer: (<div>Please navigate to the <Link path={"/agents"} label={"\"For agents\" page"}/>.</div>)
  },
]

export default function Faq() {

  const questions = faqs.map((faq) => (
    <Box sx={styles.question.container}>
      <Heading as="h3" sx={styles.question.title}>{faq.question}</Heading>
      <Box sx={styles.question.answer}>
        {faq.answer}
      </Box>
    </Box>
  ));

  return (
    <Box sx={styles.box} id="faq">
      <BlockTitle
        title="FAQ"
        styles={styles.blockTitle}
      />
      {questions}
    </Box>
  );
}

const styles = {
  box: {
    pt: ['50px', null, null, null, null, null, '100px'],
    marginTop: '80px',
  },
  blockTitle: {},
  question: {
    container: {
      marginTop: '0px',
      marginBottom: '30px',
      a: {
        transition: '500ms',
        '&:hover': {
          color: 'primary',
        },
      },
    },
    title: {
      textAlign: 'center',
    },
    answer: {
      textAlign: 'center',
    }
  },
};